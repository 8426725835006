import React from 'react'
import { withRouter } from "react-router-dom";
import { Button, Header, Label, Form, Modal, Grid } from 'semantic-ui-react'
import { Formik, Field, ErrorMessage } from 'formik';
import { Form as FormikForm } from 'formik';
import InitialMediaModel from '../../models/constants/InitialMediaModel';
import PopupSchema from '../../models/validations/PopupFormValidations';
import * as API from '../../services/api';
import CanvasManager from "../../tools/CanvasManager";

const CM = new CanvasManager();

class CreateEmergencyModal extends React.Component {
    editorContent = null;
    editorContainer = null;

    state = {
        open: false,
        folders: [],
        templates: []
    }

    setOpen = (state) => {
        if (state) {
            this.loadData().then(() => {

            });
        }
        this.setState({ open: state });
    }

    async loadData() {

        const [folders, templates] = await Promise.all([
            API.getFolders(),
            API.getTemplates('EMERGENCY')
        ]);

        this.setState({ folders, templates });
    }

    onSubmit = (values, actions) => {
        setTimeout(() => {
            // alert(JSON.stringify(values, null, 2));
            actions.setSubmitting(false);

            this.setOpen(false);

            if (this.props.history) {
                values["pageTitle"] = this.props.item.title;
                this.props.history.push('/dashboard');
                this.props.history.push('/publish/emergency', values);
            }

        }, 400);
    }

    loadTemplate(id) {
        API.getTemplate(id).then(response => {
            if (response && this.editorContent) {
                this.editorContent.setComponents('');
                this.editorContent.setStyle('');
                if (this.editorContainer)
                    this.editorContainer.style.display = 'none';

                if (response.templateJson.content) {
                    if (response.templateJson.content.html) {
                        this.editorContent.setComponents(CM.GetLiquidTemplateContent(response.templateJson.content.html));
                        if (this.editorContainer)
                            this.editorContainer.style.display = 'block';
                    }

                    if (response.templateJson.content.css) {
                        this.editorContent.setStyle(response.templateJson.content.css);
                        if (this.editorContainer)
                            this.editorContainer.style.display = 'block';
                    }
                }

                const updateComp = (component) => {
                    if (component) {
                        component.set({
                            removable: false,
                            editable: false,
                            selectable: false,
                            highlightable: false,
                            hoverable: false
                        });
                        component.get('components').each(c => updateComp(c))
                    }
                }
                this.editorContent.getComponents().each(c => updateComp(c))
            }
        });
    }

    handleCanvasContainer = (e) => {
        this.editorContainer = e;
        this.editorContent = CM.CreateCanvas('preview-design-content');
        CM.AppendDefaultHeadLinks();
        if (this.editorContainer)
            this.editorContainer.style.display = 'none';
    }

    componentWillUnmount() {

        if (this.editorContent) {
            this.editorContent.destroy();
        }
        this.editorContainer = null;
        this.editorContent = null;
    }

    render() {
        const model = Object.assign(InitialMediaModel, { type: "EMERGENCY", announcementType: "MESSAGE" });
        return (
            <Modal
                onClose={() => this.setOpen(false)}
                onOpen={() => this.setOpen(true)}
                open={this.state.open}
                trigger={this.props.trigger}
                dimmer='inverted'
            >
                <Formik
                    initialValues={model}
                    onSubmit={this.onSubmit}
                    validationSchema={PopupSchema}
                >
                    {props => {
                        const handleChange = (e, { name, value }) => props.setFieldValue(name, value);
                        const onTemplateChange = (e, { name, value }) => {
                            props.setFieldValue('folder', '6')
                            props.setFieldValue(name, value);
                            this.loadTemplate(value);
                        };
                        return (
                            <React.Fragment>
                                <Modal.Header>{this.props.item.title}</Modal.Header>
                                <Modal.Content>
                                    <Grid padded>
                                        <Grid.Row columns={2} divided="horizantally">
                                            <Grid.Column textAlign="left" verticalAlign="top">
                                                <FormikForm className="ui form" >
                                                    <Form.Select
                                                        fluid
                                                        label="Şablon"
                                                        name="template"
                                                        onChange={onTemplateChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.template}
                                                        options={this.state.templates.map((m) => { return { key: m.id, value: m.id, text: m.name } })}
                                                        placeholder='Şablon seçiniz'
                                                        className={props.errors.template && props.touched.template ? 'error' : ''}
                                                    />
                                                    {/*
                                                        <Form.Select
                                                            fluid
                                                            label="Klasör"
                                                            name="folder"
                                                            onChange={handleChange}
                                                            onBlur={props.handleBlur}
                                                            value={props.values.folder}
                                                            options={this.state.folders.map((m) => { console.log("KLASÖRLER : ", m); return { key: m.id, value: m.id, text: m.name } })}
                                                            placeholder='Klasör seçiniz'
                                                            className={props.errors.folder && props.touched.folder ? 'error' : ''}
                                                        />*/
                                                    }


                                                    <Form.Input
                                                        label="Başlık"
                                                        name="title"
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur}
                                                        value={props.values.title}
                                                        placeholder="Gönderinizin başlığını bu alana yazınız."
                                                        className={props.errors.title && props.touched.title ? 'error' : ''}
                                                    />
                                                </FormikForm>
                                            </Grid.Column>

                                            <Grid.Column textAlign="left" verticalAlign="top">
                                                {props.values.template ?

                                                    <div className="preview-canvas" style={{ paddingTop: "-40px" }}>
                                                        <div ref={this.handleCanvasContainer} id="preview-design-content" style={{ display: 'none' }}>

                                                        </div>
                                                    </div>

                                                    :

                                                    <div className="previewTemp">
                                                        <div className="previewTag">
                                                            Ön İzleme
                                                        </div>
                                                    </div>

                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button type="button" onClick={() => this.setOpen(false)}>
                                        İptal
                                    </Button>
                                    <Button
                                        onClick={props.handleSubmit}
                                        disabled={props.isSubmitting}
                                        content="Başla"
                                        className='modalStartButtonColor'
                                    />
                                </Modal.Actions>
                            </React.Fragment>
                        )
                    }}
                </Formik>
            </Modal>
        )
    }
}

export default withRouter(CreateEmergencyModal);