// EXCEL export için kullanılan component.
// DataTables kütüphanesinde tablo oluşturup pdf'i bu tabloya göre düzenliyoruz.

import React, { Component } from 'react'
import 'datatables.net-dt/css/jquery.dataTables.css'

import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-buttons/js/buttons.html5';
import 'datatables.net-buttons/js/buttons.flash';
import 'datatables.net-buttons/js/buttons.print';
import moment from "moment";

const $ = require('jquery');
$.DataTable = require('datatables.net-dt');
const jzip = require('jzip');




const columns = [
  {
    title: 'Ad',
    data: 'first_name'
  },
  {
    title: 'Soyad',
    data: 'last_name'
  },
  {
    title: 'Kullanıcı Adı',
    data: 'username'
  },
  {
    title: 'İşletim Sistemi',
    data: 'operatingSystem'
  },
  {
    title: 'Mac Adresi',
    data: 'macAddress'
  },
  {
    title: 'IP Adresi',
    data: 'ipAddress'
  },
  {
    title: 'Bilgisayar Adı',
    data: 'hostname'
  },
  {
    title: 'Versiyon',
    data: 'appVersion'
  },
  {
    title: 'Son Erişim Zamanı',
    data: 'lastActivity'
  },
  {
    title: 'Çıkış Tarihi',
    data: 'logoutDate'
  },
  {
    title: 'Çalışma Süresi (Kesintisiz)',
    data: 'loginLogoutDate'
  },
  {
    title: 'Çevrimiçi',
    data: 'isLogin'
  },
];


export default class UpDownStatusTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    }

    this.tableAPI = null

  }
  componentDidMount() {
    this.setState({ data: this.props.data })
    window.JSZip = jzip

    // const companyLogo = duyuroomIcon

  

    this.tableAPI = $(this.refs.dataTable).DataTable({
      dom: '<"data-table-wrapper"t> Bfrtip',
      searching: false,
      ordering: false,
      paging: false,
      info: false,
      data: this.props.data,
      columns,
      buttons: [
        {
          extend: 'excel',
          text: 'Excel',
          fileName: "data.xlsx",
          title: "Kullanıcı Etkinlik Raporu",
          className: 'btn btn-primary btn-sm ml-4'
        },
        {
          extend: 'csv',
          text: 'CSV',
          fileName: "rapor.csv",
          title: "Kullanıcı Etkinlik Raporu",
          className: 'btn btn-primary btn-sm'
        }
      ]
    });

  }

  componentWillUnmount() {
    $('.report-table')
      .find('table')
      .DataTable()
      .destroy(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.tableAPI.clear();
    this.tableAPI.rows.add(this.props.data);
    this.tableAPI.draw();
  }



  render() {
    return (
      <div>
        <table hidden={true} ref="dataTable" />
      </div>
    )
  }
}